<template>
  <kurcc-form-multi-choice-additional-buttons-dialog-template
    icon="mdi-camera-outline"
    title="Upload Photo" @cancel="cancel" @save="save">
    <kurcc-form-multi-choice-additional-buttons-photo-list
      ref="photos" slot="body"
      :photos.sync="localAnswer"/>
  </kurcc-form-multi-choice-additional-buttons-dialog-template>
</template>

<script>
import { updateQuestionOptionField } from '@/modules/inspection/helpers/helpers'

export default {
  name: 'KurccFormMultiChoiceAdditionalButtonsPhotoComment',
  components: {
    KurccFormMultiChoiceAdditionalButtonsPhotoList:
      () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsPhotoList'),
    KurccFormMultiChoiceAdditionalButtonsDialogTemplate:
      () => import('@/modules/inspection/components/custom-fields/KurccFormMultiChoiceAdditionalButtons/KurccFormMultiChoiceAdditionalButtonsDialogTemplate')
  },
  props: {
    answer: {
      required: true
    },
    indexes: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      initialAnswer: undefined,
      localAnswer: this.cloneAnswerOrReturnEmptyArray(),
      updateQuestionOptionField
    }
  },
  watch: {
    answer: {
      handler: function (v) {
        this.initialAnswer = v
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancel () {
      this.updateQuestionOptionField('photoCommentAnswer', this.initialAnswer, this.indexes)
      this.localAnswer = this.cloneAnswerOrReturnEmptyArray()
    },
    save () {
      this.$refs.photos.save()
      this.updateQuestionOptionField('photoCommentAnswer', this.localAnswer, this.indexes)
    },
    cloneAnswer () {
      return this.$lodash.cloneDeep(this.answer)
    },
    cloneAnswerOrReturnEmptyArray () {
      return this.cloneAnswer() ?? []
    }
  }
}
</script>
